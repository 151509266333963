import React, { Fragment, useState, useEffect } from "react";
import MarketplaceVouchersServices from "../../../apis/Marketplace/Vouchers/MarketplaceVoucherServices";
import { SecondaryButton } from "../../../components/Button/Button";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import colors from "../../../styles/colors";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { Create, DeleteForever } from "@mui/icons-material";
import ConfirmationDialog from "../../../components/Dialog/ConfirmationDialog";
import { Loading } from "../../../components/UI/Loader";
import moment from "moment/moment";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../components/Toaster/Toaster";
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  IconButton,
  Paper,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import {  
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";

const tableHead = [
  "Code",
  "Discounted Per% / AED",
  "Minimum Amount",
  "Valid To",
  "Actions",
];

function TablePaginationActions(props) {
  const theme = useTheme;

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}
function MVouchers() {
  const [rowsData, setRowsData] = useState([]);
  const [count, setCount] = useState("");
  const [voucherId, setVoucherId] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const role = localStorage.getItem("role");
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSearchParams({
      ...searchParams,
      rows: parseInt(searchParams.get("rows")),
      page: newPage + 1,
    });
    getAllVouchers(
      newPage + 1,
      parseInt(searchParams.get("rows"))
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Math.floor(event.target.value));
    setSearchParams({
      ...searchParams,
      rows: event.target.value,
      page: parseInt(searchParams.get("page")),
    });
    setPage(0);
    getAllVouchers(
      parseInt(searchParams.get("page")) + 1,
      event.target.value
    );
  };

  const getAllVouchers = async () => {
    setLoader(true);
    try {
      const { data } = await MarketplaceVouchersServices.getAllVouchers(parseInt(searchParams.get("page"))
      ? parseInt(searchParams.get("page"))
      : 1,
    parseInt(searchParams.get("rows"))
      ? parseInt(searchParams.get("rows"))
      : 10);
      
      if (data === null) return;
      setRowsData(data?.result);
      setCount(data?.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const deleteVoucher = async () => {
    try {
      const { data, message } =
        await MarketplaceVouchersServices.deletedVoucher(voucherId);
      if (data === null) return;
      SuccessToaster(message);
      getAllVouchers();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      rows: parseInt(searchParams.get("rows")) || 10,
      page: parseInt(searchParams.get("page")) || 1,
    });
    getAllVouchers();
  }, []);
  return (
    <Fragment>
      <ConfirmationDialog
        open={confirmDialog}
        onClose={() => setConfirmDialog(!confirmDialog)}
        action={deleteVoucher}
        title={"Delete this voucher"}
      />
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          p: 3,
          borderRadius: "12px",
          width: "calc(100vw - 370px)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            bgcolor: colors.white,
            p: 2,
            borderRadius: "12px",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" fontWeight="bold" color="initial">
              Vouchers
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <BreadCrumbs currentPage={["Vouchers"]} />
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            bgcolor: colors.white,
            p: 2,
            borderRadius: "12px",
            textAlign: "right",
            mt: 2,
          }}
        >
          <SecondaryButton
            disabled={role !== "A" ? true : false}
            title="Create Voucher"
            onClick={() => navigate("/marketplace/vouchers/createvoucher")}
          />
        </Box>

        {loader === true ? (
          <Box sx={{ width: 1, textAlign: "center", pt: 5 }}>
            <Loading />
          </Box>
        ) : (
          <>
          <TableContainer
            component={Paper}
            sx={{
              "&::-webkit-scrollbar": {
                bgcolor: colors.white,
                height: "8px",
                borderRadius: "10px",
                cursor: "pointer",
              },
              "&::-webkit-scrollbar-thumb": {
                bgcolor: colors.primary,
                borderRadius: "10px",
                cursor: "pointer",
              },
              "&.MuiPaper-root": {
                borderRadius: "12px",
                mt: 2,
                boxShadow: 0,
              },
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ bgcolor: colors.lightGray }}>
                  {tableHead.map((item, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.code}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.discount_percent === 0
                        ? row.discount_amount
                        : row.discount_percent}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.minimum_amount}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {moment(row.valid_to).utc().format("MMM DD, hh:mm a")}
                    </TableCell>
                    <TableCell>
                      <Box display="flex">
                        <Tooltip title="Edit">
                          <IconButton
                            disabled={role !== "A" ? true : false}
                            component={Link}
                            to={"/marketplace/vouchers/editvoucher"}
                            state={{ data: row }}
                          >
                            <Create
                              sx={{
                                color:
                                  role !== "A" ? colors.gray : colors.secondary,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            disabled={role !== "A" ? true : false}
                            onClick={() => {
                              setVoucherId(row._id);
                              setConfirmDialog(!confirmDialog);
                            }}
                          >
                            <DeleteForever
                              sx={{
                                color: role !== "A" ? colors.gray : colors.opal,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Table sx={{ marginTop: "12px" }}>
              <TablePagination
                sx={{
                  borderBottom: "none",
                  bgcolor: colors.white,
                  borderRadius: "12px",
                }}
                rowsPerPageOptions={[10, 20, 30]}
                colSpan={12}
                count={+count}
                rowsPerPage={parseInt(searchParams.get("rows"))}
                page={parseInt(searchParams.get("page")) - 1}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Table>
          </>

        )}
      </Box>
    </Fragment>
  );
}

export default MVouchers;
